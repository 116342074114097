* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  scroll-margin: none;
}
body {
  font-family: "Poppins";
}
a {
  text-decoration: none;
  font-size: 1.2rem;
  color: white;
}
